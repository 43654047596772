import Vue from '../main'
import validator from 'validator'

/** ----欄位驗證----
 * 銀行卡姓名
 * 銀行卡號
 * 中國省份
 * 中國市
 * 中國區
 * 中國手機號碼
 * 驗證碼
 * 密碼
 * email格式
 * 日期
 * 帳號
 **/

/**
 * 銀行卡姓名
 * @param {string} name
 * @returns {Boolean}
 */
export function isValidateName(name) {
  /** 規則：
   * 只能輸入中文
   * 2-20個字
   **/
  const isText = /[\u4e00-\u9fa5]{2,20}/
  return !!isText.test(name)
}

/**
* 銀行卡號
* @param {string} code
* @returns {Boolean}
*/
export function isValidateCardCode(code) {
  /** 規則：
   * 只能輸入數字
   * 16-19個字
   **/
  const isCard = /^\d{16,19}$/
  return !!isCard.test(code)
}

/**
* 中國手機號碼
* @param {string} mobile
* @returns {Boolean}
*/
export function isValidateMobile(mobile) {
  /** 規則：
   *
   **/
  const isMobile = /^1[3|4|5|7|8][0-9]\d{8}$/
  return !!isMobile.test(mobile)
}

/**
* 中國省份
* @param {string} province
* @returns {Boolean}
*/
export function isValidateProvince(province) {
  /** 規則：
   * 若為「省」，跳出請選擇省份的錯誤訊息
   **/
  return province !== '省'
}

/**
* 中國市
* @param {string} city
* @returns {Boolean}
*/
export function isValidateCity(city) {
  /** 規則：
   * 若為「市」，跳出請選擇市的錯誤訊息
   **/
  return city !== '市'
}

/**
* 中國区
* @param {string} area
* @returns {Boolean}
*/
export function isValidateArea(area) {
  /** 規則：
   * 若為「区」，跳出請選擇区的錯誤訊息
   **/
  return area !== '区'
}

/**
* 驗證碼
* @param {string} varify_code
* @returns {Boolean}
*/
export function isValidateVarifyCode(varify_code) {
  /** 規則：
   * 是否有輸入
   **/
  return !!varify_code
}

/**
* 驗證登錄名
* validateAccount
* @param account
* @returns {boolean}
*/

export function isValidateAccount(account = null) {
  const reg = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{5,16}$/
  if (reg.test(account)) {
    return true
  } else {
    return false
  }
}

/**
* 驗證密碼
* @param password
* @returns {boolean}
* 密碼為大於6
*/

export function isValidatePassword(password) {
  // 登入頁面驗證密碼最少需要6碼
  if (!password) {
    return false
  } else if (password.length === 0 || password.length < 6) {
    return false
  } else {
    return true
  }
}

/**
* 驗證email格式
* @param email
* @returns {boolean}
* 信箱格式
*/

export function isValidateEmail(email) {
  const isEmail = /\w ([- .]\w )*@\w ([-.]\w )*\.\w ([-.]\w )*/
  return !!isEmail.test(email)
}

/**
* 驗證金額
* @param amount
* @returns {boolean}
* 格式是否正確
*/

export function isValidateAmount(amount) {
  return !!(parseFloat(amount) === 'NaN' ||
      !parseFloat(amount) ||
      parseFloat(amount) <= 0)
}

/**
* 驗證日期
* @param date
* @returns {boolean}
* 格式是否正確
*/

export function isExistDate(dateStr) {
  const dateObj = new Date(Date.parse(dateStr)) // yyyy/mm/dd
  console.log('js:dateObj', dateObj === 'Invalid Date')
  if (dateObj === 'Invalid Date') {
    return false
  } else {
    const limitInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    const theYear = dateObj.getFullYear()
    const theMonth = dateObj.getMonth()
    const theDay = dateObj.getDate()
    const isLeap = new Date(theYear, 1, 29).getDate() === 29

    if (isLeap) {
      limitInMonth[1] = 29
    }

    return theDay <= limitInMonth[theMonth - 1]
  }
}

// 依國家驗證手機格式
export const validateMobileNumberByNation = (number = '', nation = '') => {
  const nationItems = {
    thai: 'th-TH',
    china: 'zh-CN',
    vn: 'vi-VN',
  }
  Vue.$log(number, nationItems[nation])
  return validator.isMobilePhone(number, nationItems[nation])
}
